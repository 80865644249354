<template>
  <b-container class='mt-4'>
    <b-row>
      <b-col cols='11'>
        <h4>SFTP Server Details</h4>
      </b-col>
      <b-col>
        <b-button
          :disabled='loading'
          variant='primary'
          :to='`/sftp/remote-servers/${this.$route.params.uuid}/edit`'
          class='float-right'>
          <feather type='edit'></feather>
          Edit
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if='loading'>
      <b-col cols='12'>
        <b-spinner></b-spinner>
      </b-col>
    </b-row>
    <SFTPRemoteServerDetails v-else :editable='false' :configs='configs'></SFTPRemoteServerDetails>
  </b-container>
</template>

<script>
import SFTPRemoteServerDetails from '@/views/SFTP/RemoteServers/Details.vue';

export default {
  name: 'SFTPRemoteServerView',
  components: { SFTPRemoteServerDetails },
  data: () => ({
    loading: false,
    configs: {},
  }),
  async beforeMount() {
    await this.loadRemoteServerConfig();
  },
  methods: {
    async loadRemoteServerConfig() {
      this.loading = true;
      return this.$store.dispatch('Sftp/RemoteServer/getRemoteServerConfigs', this.$route.params.uuid)
        .then(result => {
          this.configs = {
            connection_key: result.data.connection_key,
            hostname: result.data.hostname,
            port: result.data.port,
            username: result.data.username,
            use_socks_proxy: Boolean(result.data.use_socks_proxy),
            login_type: result.data.login_type,
            password: result.data.password,
            ssh_key: result.data.ssh_key,
            ssh_password: result.data.ssh_password,
            created_at: result.data.created_at,
            updated_at: result.data.updated_at,
          };
        })
        .catch(err => {
          this.$noty.error(err.message);
          console.error(err.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
